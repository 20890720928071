import { render, staticRenderFns } from "./editBuilding.vue?vue&type=template&id=397f3bda&scoped=true&"
import script from "./editBuilding.vue?vue&type=script&lang=js&"
export * from "./editBuilding.vue?vue&type=script&lang=js&"
import style0 from "./editBuilding.vue?vue&type=style&index=0&id=397f3bda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397f3bda",
  null
  
)

export default component.exports